.contact {
  padding-top: 200px;
  padding-bottom: 50px;
  h2
  {
      margin-bottom: 40px;
  }
  form {
    textarea {
      height: 200px;
    }
    .send {
      width: 200px;
      height: 50px;
      border-radius: 35px;
      border: none;
      cursor: pointer;
      font-weight: 700;
      color: #ffffff;
      background: linear-gradient(to left, $mainColor, $colorDark);
      display: block;
      margin: auto;
      margin-top: 30px;
    }
  }
}
