// start section footer

.footer
{
    background-color: $mainColor;
    padding-top: 100px;
    padding-bottom: 50px;
    color: #FFF;
    .about
    {
        padding-top: 20px;
    }
}

// end section footer