.project {
  padding-top: 200px;
  padding-bottom: 50px;
  .title {
    p {
      width: 200px;
      border-radius: 35px;
      border: none;
      cursor: pointer;
      font-weight: 700;
      color: #ffffff;
      background: linear-gradient(to left, $mainColor, $colorDark);
      display: block;
      padding: 10px;
      text-align: center;
      margin-bottom: 0px;
    }
  }
  .images
  {
      margin-top: 50px;
      .carousel
      {
          
          img
          {
              height: 400px;
          }
          .arrows
          {
            display: flex;
            margin: 10px;
            position: absolute;
            top: 0;
            right: 0;
              a
              {
                background-color: $mainColor;
                position: static;
                width: 50px;
                height: 50px;
                padding: 10px;
                border-radius: 10px;
                margin: 10px;
              }
          }
      }
  }
  .description
  {
      margin-top: 30px;
  }
}
