.projects {
  padding-top: 200px;
  padding-bottom: 50px;
  .item {
    background-color: $colorText;
    text-align: center;
    margin-bottom: 30px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    .info {
      padding-top: 30px;
      p {
        color: $colorDark;
      }
      .price {
        color: #000;
        font-weight: bold;
      }
    }
    .area {
      background-color: #282828;
      color: $colorText;
      padding-top: 10px;
      padding-bottom: 10px;
      transition: all 0.3s ease-in-out;
      p {
        margin-bottom: 0;
      }
    }
    &:hover {
      box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.35);
      .area {
        background-color: $colorLight;
        color: #000;
      }
    }
  }
}
