$mainColor: #000080;
$colorLight: #c6cbef;
$colorDark: #494ca2;
$colorText: #e3e7f1; 



body
{
    height: 1000px;
}