// loading page

.bg_load {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #eee;
  z-index: 9999999;
}

.wrapper1 {
  /* Size and position */
  font-size: 25px; /* 1em */
  width: 10em;
  height: 10em;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -100px;
  margin-left: -100px;
  z-index: 99999999;

  /* Styles */
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: 1em dashed rgba(138, 189, 195, 0.5);
  box-shadow: inset 0 0 2em rgba(255, 255, 255, 0.3),
    0 0 0 0.7em rgba(255, 255, 255, 0.3);
  animation: rota 3.5s linear infinite;

  /* Font styles */
  font-family: "Racing Sans One", sans-serif;

  color: #444;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 0.04em rgba(255, 255, 255, 0.9);
  line-height: 8em;
}

.wrapper1:before,
.wrapper1:after {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: inherit;
  box-shadow: inset 0 0 2em rgba(255, 255, 255, 0.3);
  border: 1em dashed;
}

.wrapper1:before {
  border-color: rgba(138, 189, 195, 0.2);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.wrapper1:after {
  border-color: rgba(138, 189, 195, 0.4);
  top: 1em;
  right: 1em;
  bottom: 1em;
  left: 1em;
}

.wrapper1 .inner {
  width: 100%;
  height: 100%;
  animation: rota 3.5s linear reverse infinite;
}

.wrapper1 span {
  display: inline-block;
  animation: placeholder 1.5s ease-out infinite;
}

.wrapper1 span:nth-child(1) {
  animation-name: loading-1;
}
.wrapper1 span:nth-child(2) {
  animation-name: loading-2;
}
.wrapper1 span:nth-child(3) {
  animation-name: loading-3;
}
.wrapper1 span:nth-child(4) {
  animation-name: loading-4;
}
.wrapper1 span:nth-child(5) {
  animation-name: loading-5;
}
.wrapper1 span:nth-child(6) {
  animation-name: loading-6;
}
.wrapper1 span:nth-child(7) {
  animation-name: loading-7;
}

@keyframes rota {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loading-1 {
  14.28% {
    opacity: 0.3;
  }
}

@keyframes loading-2 {
  28.57% {
    opacity: 0.3;
  }
}

@keyframes loading-3 {
  42.86% {
    opacity: 0.3;
  }
}

@keyframes loading-4 {
  57.14% {
    opacity: 0.3;
  }
}

@keyframes loading-5 {
  71.43% {
    opacity: 0.3;
  }
}

@keyframes loading-6 {
  85.71% {
    opacity: 0.3;
  }
}

@keyframes loading-7 {
  100% {
    opacity: 0.3;
  }
}

// slider
.carousel {
  .carousel-inner {
    .carousel-item {
      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($mainColor, 0.8);
      }
    }
  }
  @media (max-width: 991px) {
    margin-top: 97px;
  }
}

// start section search
.home_search {
  width: 100%;
  z-index: 100;
  .fourm {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: rgba($mainColor, 0.8);
    form {
      width: 600px;
      height: 450px;
      background-color: #fff;
      position: fixed;
      left: 50%;
      top: 50%;
      margin-left: -300px;
      margin-top: -225px;
      padding: 50px;
      textarea {
        height: 135px;
      }
      input {
        &[type="submit"] {
          width: 200px;
          border-radius: 35px;
          border: none;
          cursor: pointer;
          font-weight: 700;
          color: #ffffff;
          background: linear-gradient(to left, $mainColor, $colorDark);
          display: block;
          padding: 10px;
          text-align: center;
        }
      }
      button {
        width: 200px;
        border-radius: 35px;
        border: none;
        cursor: pointer;
        font-weight: 700;
        color: #ffffff;
        background: linear-gradient(to left, $mainColor, $colorDark);
        display: block;
        padding: 10px;
        text-align: center;
      }
      @media (max-width: 991px) {
        width: 300px;
        height: 500px;
        margin-left: -149px;
        margin-top: -250px;
        padding: 30px 50px;
        textarea {
          height: 70px;
        }
      }
    }
  }
  .home_search_container {
    position: absolute;
    top: -25vh;
    left: 15px;
    width: calc(100% - 30px);
    height: 90px;
    border-radius: 45px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.33);
    .home_search_content {
      width: 100%;
      height: 100%;
      background: #ffffff;
      border-radius: 35px;
      .search_form {
        position: relative;
        height: 100%;
        .search_form_content {
          width: 100%;
          height: 100%;
          padding-left: 14px;
          padding-right: 11px;
          & > div {
            width: 20%;
            height: 100%;
            padding-left: 18px;
            padding-right: 2px;
            &:not(:last-child) {
              border-right: solid 2px #d1d1d1;
            }
            .search_form_select {
              display: block;
              position: relative;
              top: 20%;
              transform: translateY(-50%);
              width: 100%;
              appearance: none;
              border: none;
              outline: none;
              font-size: 13px;
              font-weight: 400;
              color: #6b6b6b;
              cursor: pointer;
            }
          }
        }
        .search_form_button {
          width: 193px;
          height: 100%;
          border-radius: 35px;
          border: none;
          outline: none;
          cursor: pointer;
          font-size: 14px;
          font-weight: 700;
          color: #ffffff;
          text-transform: uppercase;

          background: linear-gradient(to left, $colorText, $colorDark);
        }
      }
    }
  }
  @media (max-width: 991px) {
    .home_search_container {
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      height: auto;
      margin-top: 80px;
      margin-bottom: 130px;
      background: linear-gradient(to left, $mainColor, $colorDark);
      .home_search_content {
        padding-top: 20px;
        padding-bottom: 20px;
        .search_form {
          .search_form_content {
            & > div {
              width: 100%;
              height: 50px;
              &:not(:last-child) {
                border-right: none;
                border-bottom: solid 1px #d1d1d1;
              }
            }
          }
          .search_form_button {
            position: absolute;
            bottom: -80px;
            left: -10px;
            height: 40px;
            width: calc(100% + 20px);
          }
        }
      }
    }
  }
}

// start section about

.about-home {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: $colorDark;
  color: #fff;
  .show-more {
    width: 200px;
    height: 50px;
    border-radius: 35px;
    border: none;
    cursor: pointer;
    font-weight: 700;
    color: #ffffff;
    background: linear-gradient(to left, $mainColor, $colorLight);
    display: block;
    margin: auto;
    margin-top: 30px;
    a
    {
      color: #FFF;
      text-decoration: none;
    }
  }
}

// end section about

// start section project

.recent {
  padding-top: 50px;
  padding-bottom: 50px;
  h3 {
    margin-bottom: 30px;
    color: $mainColor;
  }
  .owl-dots {
    display: none;
  }
  .owl-nav {
    position: absolute;
    top: -80px;
    right: 0;
    button {
      width: 40px;
      &[class*="owl-"]:hover {
        background: $mainColor;
      }
      span {
        font-size: 30px;
      }
    }
  }
  .item {
    a
    {
      text-decoration: none;
    }
    background-color: $colorText;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    .info {
      padding-top: 30px;
      p {
        color: $colorDark;
      }
      .price {
        color: $mainColor;
        font-weight: bold;
      }
    }
    .area {
      background-color: #282828;
      color: $colorText;
      padding-top: 10px;
      padding-bottom: 10px;
      transition: all 0.3s ease-in-out;
      p {
        margin-bottom: 0;
      }
    }
    &:hover {
      box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.35);
      .area {
        background-color: $mainColor;
        color: #fff;
      }
    }
  }
  .show-more {
    width: 200px;
    height: 50px;
    border-radius: 35px;
    border: none;
    cursor: pointer;
    font-weight: 700;
    color: #ffffff;
    background: linear-gradient(to left, $mainColor, $colorDark);
    display: block;
    margin: auto;
    margin-top: 30px;
    a
    {
      color: #FFF;
      text-decoration: none;
    }
  }
}

// end section project

// start good daels

.good-daels {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: $mainColor;
  h3 {
    margin-bottom: 30px;
    color: $colorLight;
  }
  .owl-dots {
    display: none;
  }
  .owl-nav {
    position: absolute;
    top: -80px;
    right: 0;
    button {
      width: 40px;
      &[class*="owl-"]:hover {
        background: $colorLight;
      }
      span {
        font-size: 30px;
      }
    }
  }
  .item {
    background-color: $colorText;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    a
    {
      text-decoration: none;
    }
    .info {
      padding-top: 30px;
      p {
        color: $colorDark;
      }
      .price {
        color: #000;
        font-weight: bold;
      }
    }
    .area {
      background-color: #282828;
      color: $colorText;
      padding-top: 10px;
      padding-bottom: 10px;
      transition: all 0.3s ease-in-out;
      p {
        margin-bottom: 0;
      }
    }
    &:hover {
      box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.35);
      .area {
        background-color: $colorLight;
        color: #000;
      }
    }
  }
}

// end good daels

// start section subscribe

.subscribe {
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url("../img/newsletter.jpg");
  background-size: cover;
  color: #fff;
  font-weight: bold;
  @media (max-width: 991px) {
    form > div {
      display: block !important;
    }
  }
  input {
    &[type="submit"] {
      width: 200px;
      height: 50px;
      border-radius: 35px;
      border: none;
      cursor: pointer;
      font-weight: 700;
      color: #ffffff;
      background: linear-gradient(to left, $mainColor, $colorDark);
      display: block;
      margin: auto;
      position: absolute;
      right: 0;
      @media (max-width: 991px) {
        position: static;
        margin-top: 20px;
      }
    }
    &[type="email"] {
      height: 50px;
      border-radius: 35px;
    }
  }
  p {
    color: $mainColor;
  }
}

// end section subscribe
