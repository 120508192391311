.services {
  padding-top: 200px;
  padding-bottom: 50px;
  .first {
    background-color: $mainColor;
    color: #fff;
    padding: 20px;
  }
  .second {
    background-color: $colorDark;
    color: #fff;
    padding: 20px;
  }
  .third {
    background-color: $colorLight;
    color: #000;
    padding: 20px;
  }
  @media (max-width: 767px) {
    text-align: center;
  }
}
