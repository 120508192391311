.navbar {
  background-color: $mainColor;
  border-bottom: 3px solid $colorText;
  .navbar-collapse {
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $colorText;
          font-weight: bold;
          transition: all 0.3s ease-in-out;
          &:hover {
            color: #fff;
          }
        }
      }
    }
    .navbar-nav.buttons {
      button,
      .button-Lang {
        background-color: $mainColor;
        border-radius: 20px;
        position: relative;
        color: #fff;
        border: none;
        padding: 5px;
        transition: all 0.3s ease-in-out;
        text-decoration: none;
        &:after {
          position: absolute;
          content: "";
          top: -3px;
          right: -3px;
          bottom: -3px;
          left: -3px;
          background: linear-gradient(to left, $colorText, $colorDark);
          z-index: -1;
          border-radius: 20px;
        }
        &:hover {
          background-color: $colorDark;
        }
        &:first-of-type {
          margin-right: 30px;
          i {
            margin-right: 10px;
          }
          @media (max-width: 991px) {
            width: 200px;
            margin: 20px auto;
            text-align: center;
          }
        }
      }
      @media (max-width: 991px) {
        margin-top: 20px;
        button {
          &.button-call {
            margin: 20px auto;
            width: 200px;
          }
        }
      }
    }
  }
}
